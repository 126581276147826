import MainComponent from "components/MainComponent";
import React, { useState, useEffect } from "react";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SelectState from "./State";
import useStateTax from "customHooks/StateTax/useStateTax";
import { toast } from "react-toastify";
import County from "./county";
import SelectWithSearch from "./SelectWithSearch";
import * as XLSX from "xlsx";
import { formatDataStateCountyExcelData, randomKey } from "utils/utils";
import EconomyNexus from "./EconomyNexus";
import SelectActive from "./active-inactive";

const Tax = () => {
  const [editStateTax, setEditStateTax] = useState(false);
  const [editStateUseTax, setEditStateUseTax] = useState(false);
  const [editStateOtherTax, setEditStateOtherTax] = useState(false);
  const [editCounty, setEditCounty] = useState(false);
  const [editCountyTax, setEditCountyTax] = useState(false);
  const [taxState, setTaxState] = useState();
  const [active, setActive] = useState();
  const [stateTaxRate, setStateTaxRate] = useState(0);
  const [stateUseTax, setStateUseTax] = useState(0);
  const [stateOtherTax, setStateOtherTax] = useState(0);
  const [county, setCounty] = useState();
  const [countyTaxRate, setCountyTaxRate] = useState(0);
  const [stateId, setStateId] = useState();
  const [addCountyValue, setAddCounty] = useState();
  const [total, setTotal] = useState(0);
  const [editCountyName, setEditCountyName] = useState();
  const [editCountyNameData, setEditCountyNameData] = useState("");
  const [countyId, setCountyId] = useState();

  const [zipCode, setZipCode] = useState();
  const fileInputRef = React.useRef(null);

  const [showSheetModal, setShowSheetModal] = useState(false);

  const [sheetUploadData, setSheetUploadData] = useState([]);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(true);
  const [selectedState, setSelectedState] = useState([]);
  const [isShouldApplyStateAndLocalTaxToOneTimeSetup, setIsShouldApplyStateAndLocalTaxToOneTimeSetup] = useState(false);
  const [isShouldApplyCountyTaxToOneTimeFee, setIsShouldApplyCountyTaxToOneTimeFee] = useState(false);
  const [isShouldApplyOtherTaxToOneTimeFee, setIsShouldApplyOtherTaxToOneTimeFee] = useState(false);
  const [isShouldApplyUseTaxToOneTimeFee, setIsShouldApplyUseTaxToOneTimeFee] = useState(false);
  const {
    stateAndCounties,
    updateStateTax,
    updateCountyTax,
    addNewCounty,
    getCountyAndState,
    currentState,
    zipCodes,
    uploadZipCodesWithStateAndCounty,
    uploadError,
    uploadResponse,
    isLoading,
    selectedCounty,
    updateState,
    setStateAndCounties,
  } = useStateTax();

  useEffect(() => {
    if (!isLoading) {
      if (uploadError) {
        toast.error("Failed to upload");
      }

      if (uploadResponse) {
        toast.success("Successfully uploaded");
        setShowSheetModal(false);
      }
    }
  }, [uploadError, uploadResponse, isLoading]);

  useEffect(() => {
    if (stateAndCounties && stateAndCounties.length && taxState) {
      if (stateAndCounties[0]) {
        const stateAndCountry = stateAndCounties[0];
        let selectTaxRate = countyTaxRate
          ? Number(countyTaxRate)
          : selectedCounty
          ? selectedCounty.countyTax
          : stateAndCountry.countyTax;
        setSelectedState(stateAndCounties);

        if (stateAndCountry.active) {
          setActive("active");
        } else {
          setActive("in-active");
        }

        // if it selcted in county dropdown
        if (selectedCounty) {
          setCountyTaxRate(selectedCounty.countyTax);
        } else {
          if (stateAndCountry?.countyTax) {
            setCountyTaxRate(stateAndCountry?.countyTax);
          }
        }

        let tax_total =
          Number(stateAndCountry.stateTaxRate) +
          Number(stateAndCountry.stateUseTax) +
          Number(stateAndCountry.stateOtherTax) +
          selectTaxRate;
        setTotal(tax_total);
      }
    } else {
      setSelectedState([]);
      setCountyTaxRate(0);
      setTotal(0);
    }
  }, [stateAndCounties, selectedCounty]);

  useEffect(() => {
    if (stateAndCounties && stateAndCounties.length) {
      const stateAndCountry = stateAndCounties[0];
      let tax_total =
        Number(stateAndCountry.stateTaxRate) +
        Number(stateAndCountry.stateUseTax) +
        Number(stateAndCountry.stateOtherTax) +
        Number(countyTaxRate);
      setTotal(tax_total);
    }
  }, [countyTaxRate]);

  const handleOnClickSaveCountyData = (body) => {
    if (taxState) {
      addNewCounty({
        ...body,
        stateId: taxState,
      });
    } else {
      toast.error("Select a state first");
    }
  };

  const handleOnClickSaveCountyTax = (body) => {
    const stateCounty = stateAndCounties.filter((state) => {
      if (state.countyId === countyId) {
        return state;
      }
    });

    if (taxState && stateCounty && stateCounty.length && countyId) {
      let updateCountyTaxData = {
        ...body,
        id: stateCounty[0]._id,
        countyId: countyId,
      };
      updateCountyTax(updateCountyTaxData);
      if (body.isDeleted) {
        const remaningCounties = stateAndCounties.filter((state) => {
          if (state.countyId !== countyId) {
            return state;
          }
        });
        setStateAndCounties(remaningCounties);
      } else {
        const counties = stateAndCounties.map((state) => {
          if (state.countyId === countyId) {
            return { ...state, county: body.county || state.county };
          } else {
            return state;
          }
        });
        setStateAndCounties(counties);
      }
    } else {
      toast.error("Select a state first");
    }
  };

  const handleOnClickSaveStateTax = (body) => {
    if (taxState) {
      // create_tax_data({
      //   ...body,
      //   state: taxState,
      // });
      updateStateTax({
        ...body,
        state: taxState,
      });
    } else {
      toast.error("Select a state first");
    }
  };

  const onStateChange = (e) => {
    const stateName = e.target.value;
    console.log("stateName", stateName);

    if (stateName) {
    getCountyAndState(stateName, "");
    }
    setTaxState(stateName);
    setActive("in-active");
    // let select_state = stateAndCounties.filter((val) => val.state == e.target.value);
    // if (select_state && select_state.length && select_state[0].active) {
    //   console.log("I am here");
    //   setActive("active");
    // } else {
    //   console.log("I am not here");
    //   setActive("in-active");
    // }
  };

  const onCountyChange = (e) => {
    setCounty(e.target.value);
    const selectedCountyId = e.target.value;
    const editCountyNameData = stateAndCounties.filter((state) => {
      if (state.countyId === selectedCountyId) {
        return state;
      }
    });
    setEditCountyNameData(editCountyNameData[0].county);
    setCountyId(selectedCountyId);
    getCountyAndState(taxState, selectedCountyId);
  };

  const onZipCodeChange = (e) => {
    const zipCodeData = zipCodes.filter(
      (code) => code.code === parseInt(e.target.value)
    );
    // if (zipCodeData[0]) {
    //   setCountyTaxRate(zipCodeData.percentage);
    // }
  };

  const selected_active = (e) => {
    setActive(e.target.value);
    console.log("stateAndCounties", stateAndCounties, taxState);
    if (taxState) {
      updateState({
        stateName: taxState,
        status: e.target.value == "active" ? true : false,
      });
    }
  };

  const handleCheckApplyCountyTaxToOneTimeFee = (e) => {
    setIsShouldApplyCountyTaxToOneTimeFee(e.target.checked);

    if (taxState) {
      updateState({
        stateName: taxState,
        applyCountyTaxToOneTimeFee: e.target.checked,
      });
    }
  };

  const handleCheckApplyOtherTaxToOneTimeFee = (e) => {
    
    setIsShouldApplyOtherTaxToOneTimeFee(e.target.checked);

    if (taxState) {
      updateState({
        stateName: taxState,
        applyOtherTaxToOneTimeFee: e.target.checked,
      });
    }
  };

  const handleCheckApplyUseTaxToOneTimeFee = (e) => {
    setIsShouldApplyUseTaxToOneTimeFee(e.target.checked);

    if (taxState) {
      updateState({
        stateName: taxState,
        applyUseTaxToOneTimeFee: e.target.checked,
      });
    }
  };

  const handleCheckApplyTaxToOneTimeFee = (e) => {
    setIsShouldApplyStateAndLocalTaxToOneTimeSetup(e.target.checked);

    if (taxState) {
      updateState({
        stateName: taxState,
        applyTaxToOneTimeFee: e.target.checked,
      });
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming there's only one sheet in the Excel file
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert the sheet to JSON format
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      const formatData = formatDataStateCountyExcelData(jsonData);
      // Handle the parsed Excel data here
      console.log({ formatData, jsonData });
      setSheetUploadData(formatData);

      setShowSheetModal(true);
    };

    reader.readAsArrayBuffer(file);
  };

  const uploadSheetDataToBackend = () => {
    const body = {
      typeOfUpload: "sheet",
      sheetData: sheetUploadData,
    };
    uploadZipCodesWithStateAndCounty(body);
  };

  const toggle = () => {
    setShowSheetModal(!showSheetModal);
  };

  const showSheetModalForConfirmation = () => {
    return (
      <Modal isOpen={showSheetModal} toggle={toggle}>
        {/* <ModalHeader toggle={toggle}>Are you sure to delete it?</ModalHeader> */}
        <ModalBody>
          {sheetUploadData.map((county) => (
            <div key={randomKey()}>
              <h2>Sheet Overview</h2>
              <h5>State: {county.state}</h5>
              <h5>
                County: {county.county} - {county.percentage}%
              </h5>

              <div>
                <h5>Zipcodes: {county.zipCodes.length}</h5>

                <ul style={{ listStyle: "none" }}>
                  {county.zipCodes.map((zip, index) => (
                    <li key={randomKey()}>
                      {index + 1}. {zip.code}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={isLoading}
            onClick={() => {
              uploadSheetDataToBackend();
            }}
          >
            Confirm And Upload
          </Button>{" "}
          <Button type="outline" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
  
  const isNumber = (value) => {
    return RegExp(/^-?\d+\.?\d*$/).test(value);
  }

  useEffect(() => {
    if (currentState) {
      setActive(currentState.active ? "active" : "in-active");

      setIsShouldApplyStateAndLocalTaxToOneTimeSetup(currentState.applyStateTaxToOneTimeFee ?? false)
      setIsShouldApplyCountyTaxToOneTimeFee(currentState.applyCountyTaxToOneTimeFee ?? false)
      setIsShouldApplyOtherTaxToOneTimeFee(currentState.applyOtherStateTaxToOneTimeFee ?? false)
      setIsShouldApplyUseTaxToOneTimeFee(currentState.applyUseStateTaxToOneTimeFee ?? false)

      setStateTaxRate(currentState.stateTaxRate);
      setStateUseTax(currentState.stateUseTax);
      setStateOtherTax(currentState.stateOtherTax);
    }
  }, [currentState]);

  return (
    <MainComponent>
      <div className="_container_right _user_page">
        <Row style={{ margin: "1rem" }}>
          <Col md="6" lg="6">
            <SelectState
              label="State"
              value={taxState ? taxState : ""}
              onChange={onStateChange}
              disabled={""}
            />
          </Col>

          <Col md="6" lg="6">
            <SelectActive
              label="Active/Inactive"
              value={
                active
                  ? active
                  : taxState &&
                    selectedState &&
                    selectedState.length &&
                    selectedState[0].active
                  ? "active"
                  : taxState &&
                    selectedState &&
                    selectedState.length &&
                    !selectedState[0].active
                  ? "in-active"
                  : taxState && selectedState.length == 0
                  ? "in-active"
                  : ""
              }
              onChange={selected_active}
              disabled={""}
            />
          </Col>
        </Row>
        <Row style={{ margin: "1rem", alignItems: "center" }}>
          <Col md="3" lg="3">
            <label htmlFor="">State Tax Rate</label>
            <Form.Control
              type="text"
              value={
                stateTaxRate
              }
              onChange={(e) => {
                if (isNumber(e.target.value)) {
                  let value = e.target.value;
                  if (value.startsWith('0') && value.length > 1 && !value.startsWith('0.')) {
                    value = value.replace(/^0+/, '');
                  }
                  setStateTaxRate(value);
                } else if (e.target.value === '') {
                  setStateTaxRate(0);
                }
              }}
              disabled={!editStateTax}
            />
          </Col>
          <Col md="3" lg="3">
            {!editStateTax ? (
              <button
                className="btn btn-primary mt-4"
                onClick={() => setEditStateTax(!editStateTax)}
              >
                Edit
              </button>
            ) : (
              <button
                className="btn btn-success mt-4"
                onClick={() => {
                  handleOnClickSaveStateTax({ stateTaxRate: Number(stateTaxRate) });
                  setEditStateTax(!editStateTax);
                }}
              >
                Save
              </button>
            )}
          </Col>

          <Col md="3" lg="3">
            <label htmlFor="">State Use Tax</label>
            <Form.Control
              type="text"
              value={
                stateUseTax
              }
              onChange={(e) => {
                if (isNumber(e.target.value)) {
                  let value = e.target.value;
                  if (value.startsWith('0') && value.length > 1 && !value.startsWith('0.')) {
                    value = value.replace(/^0+/, '');
                  }
                  setStateUseTax(value);
                } else if (e.target.value === '') {
                  setStateUseTax(0);
                }
              }}
              disabled={!editStateUseTax}
            />
          </Col>
          <Col md="3" lg="3">
            {!editStateUseTax ? (
              <button
                className="btn btn-primary mt-4"
                onClick={() => setEditStateUseTax(!editStateUseTax)}
              >
                Edit
              </button>
            ) : (
              <button
                className="btn btn-success mt-4"
                onClick={() => {
                  handleOnClickSaveStateTax({
                    stateUseTax: Number(stateUseTax),
                  });
                  setEditStateUseTax(!editStateUseTax);
                }}
              >
                Save
              </button>
            )}
          </Col>
        </Row>

        <Row style={{ margin: "1rem" }}>
          <Col md="3" lg="3">
            <label htmlFor="">State Other Tax</label>
            <Form.Control
              type="text"
              value={
                stateOtherTax
              }
              onChange={(e) => {
                if (isNumber(e.target.value)) {
                  let value = e.target.value;
                  if (value.startsWith('0') && value.length > 1 && !value.startsWith('0.')) {
                    value = value.replace(/^0+/, '');
                  }
                  setStateOtherTax(value);
                } else if (e.target.value === '') {
                  setStateOtherTax(0);
                }
              }}
              disabled={!editStateOtherTax}
            />
          </Col>
          <Col md="3" lg="3">
            {!editStateOtherTax ? (
              <button
                className="btn btn-primary mt-4"
                onClick={() => setEditStateOtherTax(!editStateOtherTax)}
              >
                Edit
              </button>
            ) : (
              <button
                className="btn btn-success mt-4"
                onClick={() => {
                  handleOnClickSaveStateTax({
                    stateOtherTax: Number(stateOtherTax),
                  });
                  setEditStateOtherTax(!editStateOtherTax);
                }}
              >
                Save
              </button>
            )}
          </Col>

          <Col md="3" lg="3">
            <Form.Label>Apply Applicable Taxes to One-Time Setup Fee</Form.Label>
            <Form.Check
              type="checkbox"
              label="State"
              disabled={!taxState}
              checked={isShouldApplyStateAndLocalTaxToOneTimeSetup}
              onChange={handleCheckApplyTaxToOneTimeFee}
            />
                        <Form.Check
              type="checkbox"
              label="County"
              disabled={!taxState}
              checked={isShouldApplyCountyTaxToOneTimeFee}
              onChange={handleCheckApplyCountyTaxToOneTimeFee}
            />
                                    <Form.Check
              type="checkbox"
              label="Other"
              disabled={!taxState}
              checked={isShouldApplyOtherTaxToOneTimeFee}
              onChange={handleCheckApplyOtherTaxToOneTimeFee}
            />
            <Form.Check
              type="checkbox"
              label="Use"
              disabled={!taxState}
              checked={isShouldApplyUseTaxToOneTimeFee}
              onChange={handleCheckApplyUseTaxToOneTimeFee}
            />
          </Col>
        </Row>

        <Row style={{ height: "10vh" }}></Row>

        <Row style={{ margin: "1rem" }}>
          <Col md="6" lg="6">
            <label htmlFor="">Add County</label>
            <Form.Control
              type="text"
              value={addCountyValue ? addCountyValue : ""}
              onChange={(e) => {
                setAddCounty(e.target.value);
                if (e.target.value) {
                  setIsSaveBtnDisable(false);
                } else {
                  setIsSaveBtnDisable(true);
                }
              }}
              disabled={!editCounty}
            />
          </Col>

          <Col md="3" lg="3">
            {!editCounty ? (
              <button
                className="btn btn-primary mt-4"
                onClick={() => {
                  setEditCounty(!editCounty);
                  setAddCounty("");
                }}
              >
                Add
              </button>
            ) : (
              <button
                className="btn btn-success mt-4"
                onClick={() => {
                  handleOnClickSaveCountyData({
                    county: addCountyValue,
                  });
                  setEditCounty(!editCounty);
                  setAddCounty("");
                }}
                disabled={isSaveBtnDisable}
              >
                Save
              </button>
            )}
          </Col>
        </Row>
        <Row style={{ marginLeft: "1rem" }}>
          <Col md="6">
            {editCountyName ? (
              <Form.Group style={{ marginTop: "1.5rem" }}>
                <Form.Control
                  type="text"
                  value={editCountyNameData}
                  onChange={(e) => {
                    setEditCountyNameData(e.target.value);
                  }}
                />
              </Form.Group>
            ) : (
              <County
                label="County"
                value={county ? county : ""}
                onChange={onCountyChange}
                disabled={""}
                counties={stateAndCounties}
                required={true}
              />
            )}

            {!editCountyName ? (
              <button
                className="btn btn-primary mt-4"
                disabled={!county}
                onClick={() => {
                  setEditCountyName(!editCountyName);
                }}
              >
                Edit
              </button>
            ) : (
              <>
                <button
                  className="btn btn-success mt-4"
                  onClick={() => {
                    console.log("SAVE", editCountyNameData);
                    const body = {
                      county: editCountyNameData,
                      isDeleted: false,
                    };
                    handleOnClickSaveCountyTax(body);
                    setEditCountyName(!editCountyName);
                  }}
                  style={{ marginRight: "1rem" }}
                >
                  Save
                </button>
                <button
                  className="btn btn-danger mt-4"
                  onClick={() => {
                    handleOnClickSaveCountyTax({
                      isDeleted: true,
                    });
                    setEditCountyName(!editCountyName);
                  }}
                  style={{ marginRight: "1rem" }}
                >
                  Delete
                </button>
                <button
                  className="btn btn-warning mt-4"
                  onClick={() => {
                    setEditCountyName(!editCountyName);
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </Col>
          {/* this col is for zipcode */}
          <Col md="6">
            {/* {taxState && county ? ( */}
            <div style={{ display: "flex" }}>
              <SelectWithSearch
                label={`ZipCodes - ${zipCodes.length}`}
                value={zipCode ? zipCode : ""}
                onChange={onZipCodeChange}
                disabled={""}
                counties={zipCodes}
                required={true}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <label htmlFor="fileUpload">
                  <button
                    className="btn btn-primary"
                    onClick={handleClick}
                    style={{
                      marginLeft: "1rem",
                      marginTop: "2rem !important",
                    }}
                  >
                    Upload
                  </button>
                </label>
              </div>
            </div>
            {/* ) : null} */}
          </Col>
        </Row>

        <Row style={{ margin: "1rem" }}>
          <Col md="3" lg="3">
            <label htmlFor="">County Tax Rate</label>
            <Form.Control
              type="text"
              value={countyTaxRate}
              onChange={(e) => {
                if (isNumber(e.target.value)) {
                  let value = e.target.value;
                  if (value.startsWith('0') && value.length > 1 && !value.startsWith('0.')) {
                    value = value.replace(/^0+/, '');
                  }
                  setCountyTaxRate(value);
                } else if (e.target.value === '') {
                  setCountyTaxRate(0);
                }
              }}
              disabled={!editCountyTax}
            />
          </Col>

          <Col md="3" lg="3">
            {!editCountyTax ? (
              <button
                className="btn btn-primary mt-4"
                onClick={() => setEditCountyTax(!editCountyTax)}
              >
                Edit
              </button>
            ) : (
              <button
                className="btn btn-success mt-4"
                onClick={() => {
                  handleOnClickSaveCountyTax({
                    countyTaxRate: Number(countyTaxRate),
                  });
                  setEditCountyTax(!editCountyTax);
                }}
              >
                Save
              </button>
            )}
          </Col>

          <Col md="3" lg="3">
            <label htmlFor="">Total</label>
            <Form.Control type="text" disabled={true} value={total} />
          </Col>
        </Row>

        <EconomyNexus />
      </div>

      {showSheetModalForConfirmation()}
    </MainComponent>
  );
};

export default Tax;
