import { envTypes } from "utils/const";

const localServer = "http://localhost:4002";
const prodServer = "https://api.organizedroofer.net/";
console.log("====================================");
console.log(process.env.NODE_ENV);
console.log("====================================");
const isUnderDevelopment = process.env.NODE_ENV === envTypes.development;

export default {
  appName: "Organised Roofers",
  baseUrl: isUnderDevelopment ? localServer : prodServer,
  dbName: "adminDB",
  dbColName: "admin",
  axiosTimeout: 300000,
  dbUserId: "OR:ADMIN",
};
